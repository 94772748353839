import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { IcoArrow, IcoChevron, IcoCheck, IcoClose, IcoHelp, IcoInfo, IcoSearch, IcoRefresh } from '@redoute/icons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <p>{`Icons desc`}</p>
    <Playground __position={0} __code={'<div>\n  <IcoArrow size={16} color=\"red\" hover rotate={90} />\n  <IcoArrow size={24} active rotate={-90} />\n  <IcoArrow size={32} focus />\n  <IcoArrow size={48} disabled />\n  <IcoArrow size={64} />\n</div>\n<div>\n  <IcoChevron size={16} color=\"red\" hover rotate={90} />\n  <IcoChevron size={24} active rotate={-90} />\n  <IcoChevron size={32} focus rotate={180} />\n  <IcoChevron size={48} disabled />\n  <IcoChevron size={64} />\n</div>\n<div>\n  <IcoCheck size={16} color=\"red\" hover />\n  <IcoCheck size={24} active />\n  <IcoCheck size={32} focus />\n  <IcoCheck size={48} disabled />\n  <IcoCheck size={64} />\n</div>\n<div>\n  <IcoClose size={16} color=\"red\" hover />\n  <IcoClose size={24} active />\n  <IcoClose size={32} focus />\n  <IcoClose size={48} disabled />\n  <IcoClose size={64} />\n</div>\n<div>\n  <IcoHelp size={16} color=\"red\" hover />\n  <IcoHelp size={24} active />\n  <IcoHelp size={32} focus />\n  <IcoHelp size={48} disabled />\n  <IcoHelp size={64} />\n</div>\n<div>\n  <IcoInfo size={16} color=\"red\" hover />\n  <IcoInfo size={24} active />\n  <IcoInfo size={32} focus />\n  <IcoInfo size={48} disabled />\n  <IcoInfo size={64} />\n</div>\n<div>\n  <IcoSearch size={16} color=\"red\" hover />\n  <IcoSearch size={24} active />\n  <IcoSearch size={32} focus />\n  <IcoSearch size={48} disabled />\n  <IcoSearch size={64} />\n</div>\n<div>\n  <IcoRefresh size={16} color=\"red\" hover />\n  <IcoRefresh size={24} active />\n  <IcoRefresh size={32} focus />\n  <IcoRefresh size={48} disabled />\n  <IcoRefresh size={64} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      IcoArrow,
      IcoChevron,
      IcoCheck,
      IcoClose,
      IcoHelp,
      IcoInfo,
      IcoSearch,
      IcoRefresh,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div>
        <IcoArrow size={16} color='red' hover rotate={90} mdxType="IcoArrow" />
        <IcoArrow size={24} active rotate={-90} mdxType="IcoArrow" />
        <IcoArrow size={32} focus mdxType="IcoArrow" />
        <IcoArrow size={48} disabled mdxType="IcoArrow" />
        <IcoArrow size={64} mdxType="IcoArrow" />
    </div>
    <div>
        <IcoChevron size={16} color='red' hover rotate={90} mdxType="IcoChevron" />
        <IcoChevron size={24} active rotate={-90} mdxType="IcoChevron" />
        <IcoChevron size={32} focus rotate={180} mdxType="IcoChevron" />
        <IcoChevron size={48} disabled mdxType="IcoChevron" />
        <IcoChevron size={64} mdxType="IcoChevron" />
    </div>
    <div>
        <IcoCheck size={16} color='red' hover mdxType="IcoCheck" />
        <IcoCheck size={24} active mdxType="IcoCheck" />
        <IcoCheck size={32} focus mdxType="IcoCheck" />
        <IcoCheck size={48} disabled mdxType="IcoCheck" />
        <IcoCheck size={64} mdxType="IcoCheck" />
    </div>
    <div>
        <IcoClose size={16} color='red' hover mdxType="IcoClose" />
        <IcoClose size={24} active mdxType="IcoClose" />
        <IcoClose size={32} focus mdxType="IcoClose" />
        <IcoClose size={48} disabled mdxType="IcoClose" />
        <IcoClose size={64} mdxType="IcoClose" />
    </div>
    <div>
        <IcoHelp size={16} color='red' hover mdxType="IcoHelp" />
        <IcoHelp size={24} active mdxType="IcoHelp" />
        <IcoHelp size={32} focus mdxType="IcoHelp" />
        <IcoHelp size={48} disabled mdxType="IcoHelp" />
        <IcoHelp size={64} mdxType="IcoHelp" />
    </div>
    <div>
        <IcoInfo size={16} color='red' hover mdxType="IcoInfo" />
        <IcoInfo size={24} active mdxType="IcoInfo" />
        <IcoInfo size={32} focus mdxType="IcoInfo" />
        <IcoInfo size={48} disabled mdxType="IcoInfo" />
        <IcoInfo size={64} mdxType="IcoInfo" />
    </div>
    <div>
        <IcoSearch size={16} color='red' hover mdxType="IcoSearch" />
        <IcoSearch size={24} active mdxType="IcoSearch" />
        <IcoSearch size={32} focus mdxType="IcoSearch" />
        <IcoSearch size={48} disabled mdxType="IcoSearch" />
        <IcoSearch size={64} mdxType="IcoSearch" />
    </div>
    <div>
        <IcoRefresh size={16} color='red' hover mdxType="IcoRefresh" />
        <IcoRefresh size={24} active mdxType="IcoRefresh" />
        <IcoRefresh size={32} focus mdxType="IcoRefresh" />
        <IcoRefresh size={48} disabled mdxType="IcoRefresh" />
        <IcoRefresh size={64} mdxType="IcoRefresh" />
    </div>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      